import React from 'react';
import { Grid } from '@material-ui/core';
import { WeeklyAlerts } from './WeeklyAlerts';
import { WeeklyAlertsSeverity } from './WeeklyAlertsSeverity';
import { WeeklyAlertsResponders } from './WeeklyAlertsResponder';
import { QuarterlyAlertsResponders } from './QuarterlyAlertsResponder';
import { HourlyAlerts } from './HourlyAlerts';
import { MonthlyAlertsResponders } from './MonthlyAlertsResponder';
import { DailyAlertsResponders } from './DailyAlertsResponder';
import { DailyAlerts } from './DailyAlerts';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import moment from 'moment';
import { opsgenieApiRef } from '../../api';
import { useAsync } from 'react-use';
import { Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { Context, DEFAULT_BUSINESS_HOURS_END, DEFAULT_BUSINESS_HOURS_START } from '../../analytics';
import { InfoPanel } from '../InfoPanel';
import { WeeklyImpactResponders } from './WeeklyImpactResponder';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { applyCustomFilters, statusOptions, priorityOptions } from '../../filters'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export const Analytics = () => {
  const configApi = useApi(configApiRef);
  const opsgenieApi = useApi(opsgenieApiRef);
  
  const { value: teams, loading: teamsLoading, error: teamsError } = useAsync(async () => {
    return opsgenieApi.getTeams();
  }, []);

  const { 
    value: alerts, 
    loading: alertsLoading, 
    error: alertsError, 
    dateRange, 
    setDateRange, 
    status, 
    setStatus, 
    priority, 
    setPriority 
  } = applyCustomFilters(opsgenieApi);

  if (alertsLoading || teamsLoading) {
    return <Progress />;
  } else if (alertsError) {
    return <Alert severity="error">{alertsError.message}</Alert>;
  } else if (teamsError) {
    return <Alert severity="error">{teamsError.message}</Alert>;
  }

  const context: Context = {
    from: moment(dateRange[0]),
    to: moment(dateRange[1]),
    alerts: alerts || [],
    teams: teams || []
  };

  const businessHours = {
    start: configApi.getOptionalNumber('opsgenie.analytics.businessHours.start') || DEFAULT_BUSINESS_HOURS_START,
    end: configApi.getOptionalNumber('opsgenie.analytics.businessHours.end') || DEFAULT_BUSINESS_HOURS_END,
  };

  return (
    <React.Fragment>
      <InfoPanel
        title="Use date-picker to see graphs for a specific date range."
        message={
          <ul>
            <li>Alerts are filtered based on the selected date, status and priority.</li>
            <li>Business hours are {businessHours.start} to {businessHours.end}.</li>
            <li>Responders are read from the <code>responders</code> section of an alert.</li>
            <li>For calculating impact, start-time is read from the <code>createdAt</code> section and end-time from <code>report.closeTime</code> section of an alert.</li>
          </ul>
        }
      />
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <select 
            onChange={e => setPriority(e.target.value)} 
            value={priority} 
            className="react-daterange-picker__wrapper" 
            style={{"height" : "100%", "background": "transparent"}}
          >
            {priorityOptions.map((option) => (
              <option key={option} value={option}>
                {option ? option.toUpperCase() : "ALL PRIORITIES"}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item>
          <select 
            onChange={e => setStatus(e.target.value)} 
            value={status} 
            className="react-daterange-picker__wrapper" 
            style={{"height" : "100%", "background": "transparent"}}
          >
            {statusOptions.map((option) => (
              <option key={option} value={option}>
                {option ? option.toUpperCase() : "ALL STATUSES"}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item>
          <DateRangePicker 
            clearIcon = {null}
            rangeDivider = "TO&nbsp;"
            maxDetail = "year"
            onChange = {setDateRange}
            value = {dateRange}
          />
        </Grid>
      </Grid>
    
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <WeeklyAlerts context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <WeeklyAlertsSeverity context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <WeeklyAlertsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <MonthlyAlertsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <QuarterlyAlertsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <DailyAlertsResponders context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <HourlyAlerts context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <DailyAlerts context={context} />
        </Grid>

        <Grid item md={6} xs={12}>
          <WeeklyImpactResponders context={context} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
